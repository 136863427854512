import ControllerBalance from './ControllerBalance';
import ControllerCounter from './ControllerCounter';
import BetBlock from './bet/BetBlock';
import GameModel from '../models/GameModel';
import ControllerCoefsHistory from './ControllerCoefsHistory';
import ControllerTable from './ControllerTable';
import ControllerScoreTable from './ControllerScoreTable';
import ControllerMiniPreloader from './ControllerMiniPreloader';
import ControllerSmilesPanel from './ControllerSmilesPanel';

export default class ControllerUI {
  constructor() {
    new ControllerBalance();
    new ControllerCounter();
    new ControllerCoefsHistory();
    new ControllerTable();
    new ControllerScoreTable();
    new ControllerMiniPreloader();
    new ControllerSmilesPanel();

    this.bets = [];
    for (let i = 0; i < GameModel.bets.length; i++) {
      this.bets.push(new BetBlock(i, GameModel.bets[i]));
    }

    GameModel.onBetsChanged.add(this.onGameBetsChanged.bind(this));
  }

  onGameBetsChanged() {
    for (const item of this.bets) {
      item.destroy();
    }
    this.bets = [];

    for (let i = 0; i < GameModel.bets.length; i++) {
      this.bets.push(new BetBlock(i, GameModel.bets[i]));
    }
  }
}
