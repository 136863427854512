import GlobalDispatcher from '../../events/GlobalDispatcher';
import eWSEventId from '../../api/eWSEventId';
import GameModel from '../../models/GameModel';
import spinner from '../../../assets/images/spiner.svg';
import { cancelBet, makeBet, takeBet } from '../../api/wsRequests';
import SoundManager from '../../soundManager/SoundManager';
import eSoundsType from '../../preloaders/sounds/eSoundsType';
import ControllerTooltipMessage from './ControllerTooltipMessage';

const eButtonState = {
  EBS_BET: 'bet',
  EBS_TAKE: 'take',
  EBS_CANCEL: 'cancel',
};

export default class ButtonBet {
  constructor(element, model) {
    this.element = element;
    const errorTooltip = element.parentNode.parentNode.parentNode.getElementsByClassName('tooltip-message button')[0];
    this.controllerTooltipMessage = new ControllerTooltipMessage(errorTooltip);

    this.model = model;
    this.model.onChange.add(this.onModelChanged.bind(this));
    this.model.onWaitingForResponseChanged.add(this.onWaitingForResponseChanged.bind(this));

    this.title = element.getElementsByClassName('rates-next__title')[0];

    element.addEventListener('mouseup', this.onButtonClicked.bind(this));
    this.onModelChanged();
    this.onWaitingForResponseChanged();

    GlobalDispatcher.add(eWSEventId.EWEI_START, this.onGameStart, this);
    GlobalDispatcher.add(eWSEventId.EWEI_PROGRESS, this.onProgress, this);
    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this.onFinish, this);
  }

  onModelChanged() {
    this.changeState(this.model.roundID ? this.model.roundID === GameModel.roundID ? eButtonState.EBS_TAKE : eButtonState.EBS_CANCEL : eButtonState.EBS_BET);
  }

  onWaitingForResponseChanged() {
    if (this.model.waitingForResponse) {
      this.element.innerHTML = `<img src="${spinner}" class="spinner">`;
    } else {
      this.element.innerHTML = '';
      this.element.appendChild(this.title);
    }
  }

  onGameStart(data) {
    if (data.params.roundId === this.model.roundID)
      this.changeState(eButtonState.EBS_TAKE);
  }

  onProgress(data) {
    if (this.state !== eButtonState.EBS_TAKE && this.model.roundID && GameModel.roundID === this.model.roundID)
      this.changeState(eButtonState.EBS_TAKE);

    if (GameModel.roundID && GameModel.roundID === this.model.roundID) {
      this.title.innerHTML = `${(data.params.coef * this.model.betAmount).toFixed(GameModel.decimal)} ${GameModel.currency}`
        + `<br>${this._getLocalization('take')}`;
    }
  }

  onFinish() {
    if (this.state === eButtonState.EBS_TAKE) {
      this.changeState(eButtonState.EBS_BET);
    }
  }

  async onButtonClicked() {
    SoundManager.play(eSoundsType.EST_CLICK, 1);
    if (this.model.waitingForResponse) return;
    switch (this.state) {
      case eButtonState.EBS_BET:
        if (this._freeBetsAreOver()) return;
        makeBet(this.model.betAmount, this.model);
        break;
      case eButtonState.EBS_TAKE:
        takeBet(this.model.betID, this.model)
          .then(this._onTakeComplete.bind(this), this._onTakeError.bind(this))
          .catch(this._onTakeError.bind(this));
        break;
      case eButtonState.EBS_CANCEL:
        if (OPWrapperService.config.cancelEnabled)
          cancelBet(this.model.betID, this.model);
        break;
      default:
        console.warn(`Unhandled button state: ${this.state}`);
        break;
    }
  }

  changeState(state) {
    this.state = state;
    switch (state) {
      case eButtonState.EBS_BET:
        if (this._freeBetsAreOver()) {
          this.element.classList.remove('green', 'red');
          this.element.classList.add('disabled');
          this.title.innerHTML = this._getLocalization('blocked');
        } else {
          this.element.classList.remove('green', 'red', 'disabled');
          this.title.innerHTML = this._getLocalization('bet');
        }
        break;
      case eButtonState.EBS_TAKE:
        this.element.classList.remove('red', 'disabled');
        this.element.classList.add('green');
        break;
      case eButtonState.EBS_CANCEL:
        const cancelEnabled = OPWrapperService.config.cancelEnabled;
        this.element.classList.remove('green');
        const className = cancelEnabled ? 'red' : 'disabled';
        this.element.classList.add(className);
        const localKey = cancelEnabled ? 'cancel' : 'bet_accepted';
        this.title.innerHTML = this._getLocalization(localKey);
        break;
      default:
        console.warn(`Unhandled button state: ${state}`);
        break;
    }
  }

  _onTakeComplete(data) {
    if(document.documentElement.clientWidth > 767) return;//skip desktop
    const desc = this._getLocalization('you_take', [data.coef, `${data.winAmount} ${GameModel.currency}`]);
    this.controllerTooltipMessage.showMessage(desc);
  }

  _onTakeError(error) {
    const suff = document.documentElement.clientWidth > 767 ? 'short' : 'full';
    const desc = this._getLocalization(`error_${error.code}_${suff}`);
    this.controllerTooltipMessage.showError(desc);
  }

  _getLocalization(key, params) {
    return window.OPWrapperService.localizations.getLocalizedText(key, params);
  }

  destroy() {
    this.model.onChange.remove(this.onModelChanged.bind(this));
    this.model.onWaitingForResponseChanged.remove(this.onWaitingForResponseChanged.bind(this));
    GlobalDispatcher.remove(eWSEventId.EWEI_START, this.onGameStart, this);
    GlobalDispatcher.remove(eWSEventId.EWEI_PROGRESS, this.onProgress, this);
    GlobalDispatcher.remove(eWSEventId.EWEI_FINISH, this.onFinish, this);
  }
  _freeBetsAreOver() {
    return (window.OPWrapperService.freeBetsController.isFirstFreeBet || window.OPWrapperService.freeBetsController.isActive)
      && window.OPWrapperService.freeBetsController.data.freeBets <= 0;
  }
}
