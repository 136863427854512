import bg from '../../../assets/images/bg.webp';
import bgNight from '../../../assets/images/bg_night.webp';
import ground from '../../../assets/images/ground.webp';
import groundNight from '../../../assets/images/ground_night.webp';
import crate0 from '../../../assets/images/crate_0.webp';
import crate1 from '../../../assets/images/crate_1.webp';
import progress from '../../../assets/images/progress.webp';
import progressBg from '../../../assets/images/progress_bg.webp';
import clouds from '../../../assets/images/clouds.webp';
import tree from '../../../assets/images/tree.webp';
import bush from '../../../assets/images/bush.webp';
import treeNight0 from '../../../assets/images/tree_night_0.webp';
import treeNight1 from '../../../assets/images/tree_night_1.webp';
import mount0 from '../../../assets/images/mount_0.webp';
import mount1 from '../../../assets/images/mount_1.webp';
import mountNight0 from '../../../assets/images/mount_night_0.webp';
import mountNight1 from '../../../assets/images/mount_night_1.webp';
import bush0 from '../../../assets/images/bush_0.webp';
import bush1 from '../../../assets/images/bush_1.webp';
import bushNight0 from '../../../assets/images/bush_night_0.webp';
import bushNight1 from '../../../assets/images/bush_night_1.webp';

import beer from '../../../assets/images/smiles/beer.webp';
import fire from '../../../assets/images/smiles/fire.webp';
import flushed from '../../../assets/images/smiles/flushed.webp';
import folded_hands from '../../../assets/images/smiles/folded_hands.webp';
import four_leaf_clover from '../../../assets/images/smiles/four_leaf_clover.webp';
import money_mouth from '../../../assets/images/smiles/money_mouth.webp';
import pile_of_poo from '../../../assets/images/smiles/pile_of_poo.webp';
import screaming_in_fear from '../../../assets/images/smiles/screaming_in_fear.webp';
import slot_machine from '../../../assets/images/smiles/slot_machine.webp';
import sunglasses from '../../../assets/images/smiles/sunglasses.webp';
import swearing from '../../../assets/images/smiles/swearing.webp';
import tongue from '../../../assets/images/smiles/tongue.webp';

import openSmiles from '../../../assets/images/smiles/button_open.webp';
import closeSmiles from '../../../assets/images/smiles/button_close.webp';

import eImageType from './eImageType';
import * as PIXI from 'pixi.js';
import eSmileType from './eSmileType';

export default new class ImagePreloader {
  constructor() {
    this.imagesData = [
      { type: eImageType.EIT_BG, src: bg },
      { type: eImageType.EIT_BG_NIGHT, src: bgNight },
      { type: eImageType.EIT_CLOUDS, src: clouds },
      { type: eImageType.EIT_GROUND, src: ground },
      { type: eImageType.EIT_GROUND_NIGHT, src: groundNight },
      { type: eImageType.EIT_CRATE_0, src: crate0 },
      { type: eImageType.EIT_CRATE_1, src: crate1 },
      { type: eImageType.EIT_PROGRESS, src: progress },
      { type: eImageType.EIT_PROGRESS_BG, src: progressBg },
      { type: eImageType.EIT_TREE, src: tree },
      { type: eImageType.EIT_BUSH, src: bush },
      { type: eImageType.EIT_TREE_NIGHT0, src: treeNight0 },
      { type: eImageType.EIT_TREE_NIGHT1, src: treeNight1 },
      { type: eImageType.EIT_MOUNT0, src: mount0 },
      { type: eImageType.EIT_MOUNT1, src: mount1 },
      { type: eImageType.EIT_MOUNT_NIGHT0, src: mountNight0 },
      { type: eImageType.EIT_MOUNT_NIGHT1, src: mountNight1 },
      { type: eImageType.EIT_BUSH_0, src: bush0 },
      { type: eImageType.EIT_BUSH_1, src: bush1 },
      { type: eImageType.EIT_BUSH_NIGHT_0, src: bushNight0 },
      { type: eImageType.EIT_BUSH_NIGHT_1, src: bushNight1 },

      { type: eSmileType.EST_BEER, src: beer },
      { type: eSmileType.EST_FIRE, src: fire },
      { type: eSmileType.EST_FLUSHED, src: flushed },
      { type: eSmileType.EST_FOLDED_HANDS, src: folded_hands },
      { type: eSmileType.EST_FOUR_LEAF_CLOVER, src: four_leaf_clover },
      { type: eSmileType.EST_MONEY_MOUTH, src: money_mouth },
      { type: eSmileType.EST_PILE_OF_POO, src: pile_of_poo },
      { type: eSmileType.EST_SCREAMING_IN_FEAR, src: screaming_in_fear },
      { type: eSmileType.EST_SLOT_MACHINE, src: slot_machine },
      { type: eSmileType.EST_SUNGLASSES, src: sunglasses },
      { type: eSmileType.EST_SWEARING, src: swearing },
      { type: eSmileType.EST_TONGUE, src: tongue },

      { type: eImageType.EIT_OPEN_SMILES, src: openSmiles },
      { type: eImageType.EIT_CLOSE_SMILES, src: closeSmiles },
    ];
  }

  addAssetsToBundle(bundleAssets) {
    for (let asset of this.imagesData) {
      bundleAssets[asset.type] = asset.src;
    }
  }

  createImage(type) {
    return new PIXI.Sprite(this.getTexture(type));
  }

  getTexture(type) {
    return PIXI.Assets.get(type);
  }

  getSrc(type) {
    const index = this.imagesData.findIndex((item) => item.type === type);
    if(index === -1){
      console.error(`Unlisted image type: ${type}`)
    }
    return this.imagesData[index].src;
  }
}
