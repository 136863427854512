import AnimationLoader from '../preloaders/animations/AnimationLoader';
import eAnimationType from '../preloaders/animations/eAnimationType';
import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import eEventType from '../events/eEventType';
import GameModel from '../models/GameModel';
import eGameState from '../models/eGameState';

export default class ControllerTank {
  constructor(container) {
    this.animation = AnimationLoader.createAnimation(eAnimationType.EAT_CAT);
    const tankContainer = container.getChildByName('tank_container');
    tankContainer.addChild(this.animation);

    this.animation.x += 500;
    this.animation.y += 500;

    GlobalDispatcher.add(eWSEventId.EWEI_START, this.start, this);
    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this.finish, this);
    GlobalDispatcher.add(eEventType.EET_RESET, this.reset, this);

    this._initAnimation();

    document.addEventListener('visibilitychange', this._onVisibilityChange.bind(this));
  }

  start() {
    this.animation.state.setAnimation(0, 'start_tank', false);
    this.animation.state.addAnimation(0, 'idle_tank', true, 0);
  }

  finish(data) {
    const reason = data.params.reason;
    if (reason === 'jackpot') {
      this.animation.state.clearTracks();
      return;
    }
    this.animation.state.clearTracks();
    this.animation.skeleton.setToSetupPose();
    this.animation.state.setAnimation(0, `${reason}_explosion`, false);
  }

  reset() {
    this.animation.state.setAnimation(0, 'start_tank', false);
    this.animation.skeleton.setToSetupPose();
  }

  _onVisibilityChange() {
    if(!document.hidden) {
      this._initAnimation();
    }
  }

  _initAnimation() {
    this.reset();
    if (GameModel.state === eGameState.EGS_PROGRESS)
      this.animation.state.setAnimation(0, 'idle_tank', true);
  }
}
