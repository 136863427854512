import Event from '../utils/event';
import GameModel from './GameModel';
import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import { makeBet } from '../api/wsRequests';
import { formatCurrency } from '../utils/currency';

export default class BetModel {
  constructor() {
    //todo: Hack. defaultBetIndex must come in the "auth" package
    const betIndex = window.OPWrapperService.config.defaultBetIndex || 0;
    this._betAmount = window.OPWrapperService.freeBetsController.isFirstFreeBet || window.OPWrapperService.freeBetsController.isActive
      ? window.OPWrapperService.freeBetsController.data.bet
      : GameModel.availableBets[betIndex];
    this._autoBet = false;

    this._takeCoef = 2;
    this._autoTake = false;

    this.initBetButtons();

    this.betID = null;
    this.roundID = null;

    this.minTakeCoef = 1.35;

    this._waitingForResponse = false;

    this.onChange = new Event();
    this.onWaitingForResponseChanged = new Event();

    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this._onFinish, this);
    GlobalDispatcher.add(eWSEventId.EWEI_TAKE, this._onTake, this);
  }

  setData(data) {
    this.betAmount = data.amount;
    this.betID = data.betId;
    this.roundID = data.roundId;
    this.initBetButtons();
    if (data.autoTake) {
      this.autoTake = true;
      this.takeCoef = data.autoTake;
    }
  }

  initBetButtons() {
    const ab = GameModel ? GameModel.availableBets : null;
    const i = ab ? ab.length : 0;
    this.betsButtons = ab ? [ab[i - 4], ab[i - 3], ab[i - 2], ab[i - 1]] : [20, 50, 100, 200];
  }

  get formatBetAmount() {
    return `${formatCurrency(this._betAmount, GameModel.decimal)} ${GameModel.currency}`;
  }

  get formatTakeCoef() {
    return `×${this._takeCoef.toFixed(2)}`;
  }

  set autoBet(value) {
    this._autoBet = !!value;
    this.onChange.call();
  }

  get autoBet() {
    return this._autoBet;
  }

  set autoTake(value) {
    this._autoTake = !!value;
    this.onChange.call();
  }

  get autoTake() {
    return this._autoTake;
  }

  set betAmount(value) {
    value = value.toFixed(GameModel.decimal);
    value = Math.min(value, GameModel.maxBet);
    value = Math.max(value, GameModel.minBetAmount);
    this._betAmount = value;
    this.onChange.call();
  }

  get betAmount() {
    return this._betAmount;
  }

  set takeCoef(value) {
    this._takeCoef = Math.max(this.minTakeCoef, value);
    this.onChange.call();
  }

  get takeCoef() {
    return this._takeCoef;
  }

  set waitingForResponse(value) {
    this._waitingForResponse = !!value;
    this.onWaitingForResponseChanged.call();
    this.onChange.call();
  }

  get waitingForResponse() {
    return this._waitingForResponse;
  }

  get betSubmitted() {
    return this._waitingForResponse || this.betID !== null;
  }

  increaseBet() {
    const index = GameModel.availableBets.findIndex(bet => bet > this._betAmount);
    let value = GameModel.availableBets[index];
    value = value ? value : GameModel.maxBet;
    value = Math.min(value, GameModel.maxBet);
    value = Math.max(GameModel.minBetAmount, value);
    this._betAmount = value;
    this.onChange.call();
  }

  decreaseBet() {
    const index = GameModel.availableBets.findIndex(bet => bet >= this._betAmount);
    let value = GameModel.availableBets[index - 1];
    value = value ? value : GameModel.minBetAmount;
    this._betAmount = Math.max(GameModel.minBetAmount, value);
    this.onChange.call();
  }

  increaseTakeCoef() {
    let value = this._takeCoef + 0.1;
    this._takeCoef = Math.max(this.minTakeCoef, value);
    this.onChange.call();
  }

  decreaseTakeCoef() {
    const value = this._takeCoef - 0.1;
    this._takeCoef = Math.max(this.minTakeCoef, value);
    this.onChange.call();
  }

  setBetInfo(betID, roundID, amount) {
    if (amount) this.betAmount = amount;
    this.betID = betID;
    this.roundID = roundID;
    if (window.OPWrapperService.freeBetsController.isFirstFreeBet || window.OPWrapperService.freeBetsController.isLastFreeBet || window.OPWrapperService.realityCheck.blockedGame) this.autoBet = false;
    this.onChange.call();
  }

  onDelete() {
    GlobalDispatcher.remove(eWSEventId.EWEI_FINISH, this._onFinish, this);
    GlobalDispatcher.remove(eWSEventId.EWEI_TAKE, this._onTake, this);
  }

  _onFinish() {
    if (this.roundID && this.roundID !== GameModel.roundID && !this.waitingForResponse) return;
    if (window.OPWrapperService.freeBetsController.isFirstFreeBet || window.OPWrapperService.freeBetsController.isLastFreeBet || window.OPWrapperService.realityCheck.blockedGame) this.autoBet = false;
    if (this._autoBet && !this.waitingForResponse) {
      makeBet(this._betAmount, this);
    }
  }

  _onTake(data) {
    if (this.betID === data.params.betId) {
      this.setBetInfo(null, null);
      OPWrapperService.ControllerStatistic.win = data.params.winAmount;
      if (!GameModel.bets.find(betModel => !!betModel.betID)) OPWrapperService.realityCheck.blockedRealityCheck = false;
    }
    if (window.OPWrapperService.freeBetsController.isFirstFreeBet || window.OPWrapperService.freeBetsController.isLastFreeBet || window.OPWrapperService.realityCheck.blockedGame) this.autoBet = false;
  }
}
