import ControllerMovingBGBase from './ControllerMovingBGBase';
import GlobalDispatcher from '../../events/GlobalDispatcher';
import eWSEventId from '../../api/eWSEventId';
import ControllerCrate from '../../crate/ControllerCrate';
import GameModel from '../../models/GameModel';

export default class ControllerCratesContainer extends ControllerMovingBGBase {
  constructor(container) {
    super(container);
    this.crates = [];

    GlobalDispatcher.add(eWSEventId.EWEI_SPAWN_CRATE, this.spawnCrate, this);
    GlobalDispatcher.add(eWSEventId.EWEI_DESTROY_CRATE, this.destroyCrate, this);

    if (GameModel.crate) {
      this.spawnCrate({ params: { x: GameModel.crate.x } });
    }
  }

  reset() {
    super.reset();
    for (const item of this.crates) {
      item.destroy();
    }

    console.log(this.container.children)
    for (const item of this.container.children) {
      item.destroy();
    }
    this.crates = [];
  }

  spawnCrate(data) {
    const crate = new ControllerCrate(data.params.x, 520, this.container);
    this.crates.push(crate);
  }

  destroyCrate(data) {
    const crate = this.crates.shift();
    if (crate) crate.showAnimationDestroy(data.params.cont);
  }
}
