export default {
  EIT_BG: 'bg',
  EIT_BG_NIGHT: 'bg_night',
  EIT_CLOUDS: 'clouds',
  EIT_GROUND: 'ground',
  EIT_GROUND_NIGHT: 'ground_night',
  EIT_CRATE_0: 'crate_0',
  EIT_CRATE_1: 'crate_1',
  EIT_PROGRESS: 'progress',
  EIT_PROGRESS_BG: 'progressBg',
  EIT_TREE: 'tree',
  EIT_BUSH: 'bush',
  EIT_TREE_NIGHT0: 'treeNight0',
  EIT_TREE_NIGHT1: 'treeNight1',
  EIT_MOUNT0: 'mount0',
  EIT_MOUNT1: 'mount1',
  EIT_MOUNT_NIGHT0: 'mountNight0',
  EIT_MOUNT_NIGHT1: 'mountNight1',
  EIT_BUSH_0: 'bush_0',
  EIT_BUSH_1: 'bush_1',
  EIT_BUSH_NIGHT_0: 'bush_bight_0',
  EIT_BUSH_NIGHT_1: 'bush_bight_1',

  EIT_OPEN_SMILES: 'open_smiles',
  EIT_CLOSE_SMILES: 'close_smiles',
}
