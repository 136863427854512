import AnimationLoader from '../preloaders/animations/AnimationLoader';
import eAnimationType from '../preloaders/animations/eAnimationType';
import * as PIXI from 'pixi.js';
import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import GameModel from '../models/GameModel';
import { gsap, Linear } from 'gsap';
import ManagerResult from '../ManagerResult';
import eGameState from '../models/eGameState';
import ImagePreloader from '../preloaders/images/ImagePreloader';

export default class WaitingBetsScreen {
  constructor(container) {
    this.animation = AnimationLoader.createAnimation(eAnimationType.EAT_CAT);
    this.animation.x += 500;
    this.animation.y += 420;

    this.animation.state.setAnimation(0, 'idle_loading', true);
    this.container = new PIXI.Container();

    this._buildBlocker(this.container);
    this._buildProgress(this.container);
    this.container.addChild(this.animation);
    container.addChildAt(this.container, container.children.length - 1);
    this.animateProgress();

    this.container.visible = GameModel.state === eGameState.EGS_RESULTS;
    if (GameModel.state === eGameState.EGS_RESULTS) {
      this.animateProgress();
    }

    GlobalDispatcher.add(eWSEventId.EWEI_START, this.hide, this);
    ManagerResult.controllerWaitingBets = this;
  }

  show() {
    this.container.visible = true;
  }

  hide() {
    this.container.visible = false;
    gsap.killTweensOf(this.mask);
    this.mask.x = -750;
  }

  _buildBlocker(container) {
    this.blocker = new PIXI.Graphics();
    this.blocker.beginFill('0x000000');
    this.blocker.drawRect(0, 0, 1015, 650);
    this.blocker.alpha = 0.9;
    container.addChild(this.blocker);
  }

  _buildProgress(container) {
    const progressBg = ImagePreloader.createImage('progressBg');
    progressBg.anchor.set(0.5);
    progressBg.x = 505;
    progressBg.y = 550;
    const progress = ImagePreloader.createImage('progress');
    progress.anchor.set(0.5);
    progress.x = 505;
    progress.y = 550;

    this.mask = new PIXI.Graphics();
    this.mask.beginFill('0x000000');
    this.mask.drawRect(128, 544, progress.width, progress.height);

    progress.mask = this.mask;
    this.mask.x -= 750;

    container.addChild(progressBg);
    container.addChild(progress);
    container.addChild(this.mask);
  }

  animateProgress() {
    gsap.killTweensOf(this.mask);
    const duration = (GameModel.nextRoundIn - new Date().getTime() + GameModel.serverTimeOffset) / 1000;
    gsap.to(this.mask, { duration: duration, x: 0, ease: Linear.easeNone });
  }
}
