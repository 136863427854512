import GameModel from '../models/GameModel';

export default class ControllerBalance {
  constructor() {
    this.element = document.getElementById('balanceView');
    GameModel.onBalanceChanged.add(this._updateView.bind(this));
    this._updateView();
  }

  _updateView() {
    this.element.innerText = window.OPWrapperService.localizations.getLocalizedText('balance', [GameModel.formatBalance]);
  }
}
