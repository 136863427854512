import eAnimationType from './eAnimationType';
import * as PIXI from "pixi.js";
import { Spine } from 'pixi-spine';

class AnimationsLoader {
  constructor() {
    this.animationsData = [
      { type: eAnimationType.EAT_CAT, src: 'assets/animations/tank/limbocat_animations_.json' },
      { type: eAnimationType.EAT_CRATE, src: 'assets/animations/crate/limbocat_jackpot_.json' },
      { type: eAnimationType.EAT_FLAG, src: 'assets/animations/flag/limbocat_flag_.json' },
    ];
  }
  addAssetsToBundle(bundleAssets) {
    for (let asset of this.animationsData) {
      bundleAssets[asset.type] = asset.src;
    }
  }

  createAnimation(type) {
    const resource = PIXI.Assets.get(type);
    return new Spine(resource.spineData);
  }
}

export default new AnimationsLoader();
