import SoundManager from './soundManager/SoundManager';
import eSoundsType from './preloaders/sounds/eSoundsType';
import GlobalDispatcher from './events/GlobalDispatcher';
import eWSEventId from './api/eWSEventId';
import GameModel from './models/GameModel';
import eGameState from './models/eGameState';

export default new class ManagerSFX {
  init() {
    if (GameModel.state !== eGameState.EGS_RESULTS)
      SoundManager.play(eSoundsType.EST_SFX, 1, true, 'sfx');

    GlobalDispatcher.add(eWSEventId.EWEI_START, this._onStart, this);
    GlobalDispatcher.add(eWSEventId.EWEI_DESTROY_CRATE, this._destroyCreate, this);
    GlobalDispatcher.add(eWSEventId.EWEI_TAKE, this._flag, this);
    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this._onFinish, this);
  }

  _onStart() {
    SoundManager.play(eSoundsType.EST_SFX, 1, true, 'sfx');
  }

  _destroyCreate(data) {
    if (data.params.cont !== 0)
      SoundManager.play(eSoundsType.EST_BONUS, 1);
  }

  _flag() {
    SoundManager.play(eSoundsType.EST_FLAG, 1);
  }

  _onFinish(data) {
    SoundManager.stop(eSoundsType.EST_SFX, 'sfx');
    const reason = data.params.reason;
    if (reason !== 'jackpot') {
      SoundManager.play(eSoundsType.EST_EXPLOSION, 1, false);
    }
  }
}
