import ImagePreloader from '../../preloaders/images/ImagePreloader';
import { getRandomInt } from '../../utils/random';
import GlobalDispatcher from '../../events/GlobalDispatcher';
import eEventType from '../../events/eEventType';
import ControllerMovingBGBase from './ControllerMovingBGBase';

import { clustersInfo, eClusterType } from './clustersInfo';

export default class ControllerBGObjects extends ControllerMovingBGBase {
  constructor(mainContainer) {
    super(mainContainer);
    this.stepDevider = 2;
    this.currentClusterType = eClusterType.ECT_DAY;
    this.mountsContainer = new PIXI.Container();
    this.greeneryContainer = new PIXI.Container();
    this.container.addChild(this.mountsContainer, this.greeneryContainer);

    this._clusters = [];

    const mountains = clustersInfo[eClusterType.ECT_DAY][eClusterType.ECT_MOUNTS];
    const greenery = clustersInfo[eClusterType.ECT_DAY][eClusterType.ECT_GREENERY];
    this._spawnCluster(mountains[1], eClusterType.ECT_MOUNTS, 500);
    this._spawnCluster(greenery[2], eClusterType.ECT_GREENERY, 100);
    this._spawnCluster(greenery[3], eClusterType.ECT_GREENERY, 800);

    GlobalDispatcher.add(eEventType.EET_BACKGROUND_CHANGE_COMING, this._onBGChanged, this)
  }

  onUpdate() {
    this._trySpawnNewCluster();
    this._tryDespawnClusters();
  }

  reset() {
    super.reset();
    for (const item of this._clusters) {
      item.destroy();
    }
    this._clusters = [];
  }

  _onBGChanged(data) {
    this.currentClusterType = data.params;
  }

  _trySpawnNewCluster() {
    if (getRandomInt(300) === 42) {
      const type = getRandomInt(1) === 1 ? eClusterType.ECT_GREENERY : eClusterType.ECT_MOUNTS;
      const clusters = clustersInfo[this.currentClusterType][type];
      const itemInfo = clusters[getRandomInt(clusters.length - 1)];
      this._spawnTempCluster(itemInfo, type, -this.container.x + 1015);
    }
  }

  _tryDespawnClusters() {
    for (let i = 0; i < this._clusters.length; i++) {
      const cluster = this._clusters[i];
      if (-cluster.x - cluster.width > this.container.x) {
        this._clusters.splice(i, 1);
        cluster.destroy({ children: true });
      }
    }
  }

  _spawnTempCluster(itemInfo, type, x) {
    this._clusters.push(this._spawnCluster(itemInfo, type, x));
  }

  _spawnCluster(itemInfo, type, x) {
    const container = new PIXI.Container();
    for (let j = 0; j < itemInfo.obj.length; j++) {
      const item = itemInfo.obj[j];
      const sprite = ImagePreloader.createImage(item.image);
      sprite.x = item.x;
      sprite.y = item.y;
      sprite.scale.set(item.scale || 1);
      container.addChild(sprite);
    }
    container.y = 420;
    container.x = x;
    if (type === eClusterType.ECT_GREENERY) {
      this.greeneryContainer.addChild(container);
    } else {
      this.mountsContainer.addChild(container);
    }
    return container;
  }
}
