import ControllerMovingBGBase from './ControllerMovingBGBase';
import ImagePreloader from '../../preloaders/images/ImagePreloader';
import eImageType from '../../preloaders/images/eImageType';
import { getRandomInt } from '../../utils/random';
import GlobalDispatcher from '../../events/GlobalDispatcher';
import eEventType from '../../events/eEventType';

export default class ControllerBushes extends ControllerMovingBGBase {
  constructor(container) {
    super(container);

    this._spawnBush(eImageType.EIT_BUSH_0, 200);
    this._spawnBush(eImageType.EIT_BUSH_1, 300);
    this._spawnBush(eImageType.EIT_BUSH_0, 900);

    this._bushes = [];
    this._currentGroundType = eImageType.EIT_GROUND;

    this._imageTypes = {
      [eImageType.EIT_GROUND]: [eImageType.EIT_BUSH_0, eImageType.EIT_BUSH_1],
      [eImageType.EIT_GROUND_NIGHT]: [eImageType.EIT_BUSH_NIGHT_0, eImageType.EIT_BUSH_NIGHT_1],
    };

    GlobalDispatcher.add(eEventType.EET_COMING_GROUND, this._onGroundComingChanged, this);
  }

  onUpdate() {
    this._trySpawnBush();
    this._tryDespawnBushes();
  }

  _onGroundComingChanged(data) {
    this._currentGroundType = data.params;
  }

  reset() {
    super.reset();
    for (const item of this._bushes) {
      item.destroy();
    }
    this._currentGroundType = eImageType.EIT_GROUND;
    this._bushes = [];
  }

  _trySpawnBush() {
    if (getRandomInt(500) === 42) {
      const type = this._imageTypes[this._currentGroundType][getRandomInt(1)];
      const bush = this._spawnBush(type, -this.container.x + 1015);
      this._bushes.push(bush);
    }
  }

  _tryDespawnBushes() {
    for (let i = 0; i < this._bushes.length; i++) {
      const bush = this._bushes[i];
      if (-bush.x - bush.width > this.container.x) {
        this._bushes.splice(i, 1);
        bush.destroy({ children: true });
      }
    }
  }

  _spawnBush(type, x) {
    const bush = ImagePreloader.createImage(type);
    bush.x = x;
    bush.y = 570;
    this.container.addChild(bush);
    return bush;
  }
}
