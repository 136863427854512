import GameModel from '../../models/GameModel';

export default new class Adapter {
  init() {
    const eventManager = window.OPWrapperService.eventManager;
    this.eventManager = eventManager;
    const receiveMessageTypes = eventManager.receiveMessageTypes;
    this.postMessagesTypes = eventManager.postMessageTypes;
    eventManager.add(receiveMessageTypes.RMT_UPDATE_BALANCE, this.updateBalance, this);
    eventManager.add(receiveMessageTypes.RMT_STOP_AUTO_SPINS, this.stopAutoSpins, this);
    eventManager.add(receiveMessageTypes.RMT_SET_AUDIO, this.setAudio, this);
    eventManager.add(receiveMessageTypes.RMT_SHOW_HELP, this.showHelp, this);
    eventManager.add(receiveMessageTypes.RMT_TOGGLE_PAYTABLE, this.togglePaytable, this);

    // storeObserver.addListener(selectors.getBetAmount, this.stakeUpdate.bind(this));
    // storeObserver.addListener(selectors.getGameState, this.stateUpdated.bind(this));

    this.freespinsStarted = false;

    // controllerSounds.onSoundSettingsCahnged.add(this.audioToggle.bind(this));
  }

  // <<<<<<RECEIVE MESSAGES>>>>>
  updateBalance() {

  }

  stopAutoSpins() {
    for (const bet of GameModel.bets) {
      bet.autoBet = false;
    }
  }

  setAudio(value) {

  }

  showHelp() {

  }

  togglePaytable() {

  }

  // <<<<<<<POST MESSAGES>>>>>>>

  loadProgress(data) {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_PROGRESS, data);
  }

  loadCompleted() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_COMPLETED);
    // this.audioToggle();
    this.stakeUpdate();
  }

  audioToggle() {
    const audioState = !(controllerSounds.musicMuted && controllerSounds.soundsMuted);
    this.eventManager.dispatch(this.postMessagesTypes.PMT_AUDIO_TOGGLE, audioState);
  }

  stakeUpdate() {
    // this.eventManager.dispatch(this.postMessagesTypes.PMT_STAKE_UPDATE);
  }

  stateUpdated() {

  }

  closed() {

  }

  errorMessage(data) {
    const message = {
      errorCode: '8501',
      errorMessage: data.params.message
    };
    //EveryMatrix.errorMessage(message);
  }
}
