import NumberInput from './NumberInput';
import SoundManager from '../../soundManager/SoundManager';
import eSoundsType from '../../preloaders/sounds/eSoundsType';

export const eButtonType = {
  EBT_MINUS: 'minus',
  EBT_PLUS: 'plus',
};

export default class BaseValueContainer {
  constructor(element, model) {
    this.model = model;
    this.model.onChange.add(this.onModelChanged.bind(this));

    this.element = element;

    this.amountInput = new NumberInput(element.getElementsByClassName('amount_element')[0]);
    this.amountInput.onBlur.add(this.onInputBlur.bind(this));

    this.autoCheckbox = element.getElementsByClassName('auto_checkbox')[0];
    this.autoCheckbox.addEventListener('change', this.onCheckboxChanged.bind(this));

    this.buttonMinus = element.getElementsByClassName('button_minus')[0];
    this.buttonMinus.addEventListener('click', this.onMinusClicked.bind(this));
    this.buttonMinus.addEventListener('mousedown', () => this.onKeyDown(eButtonType.EBT_MINUS));
    this.buttonMinus.addEventListener('mouseup', () => this.onKeyUp(eButtonType.EBT_MINUS));
    this.buttonMinus.addEventListener('mouseout', () => this.onKeyUp(eButtonType.EBT_MINUS));

    this.buttonPlus = element.getElementsByClassName('button_plus')[0];
    this.buttonPlus.addEventListener('click', this.onPlusClicked.bind(this));
    this.buttonPlus.addEventListener('mousedown', () => this.onKeyDown(eButtonType.EBT_PLUS));
    this.buttonPlus.addEventListener('mouseup', () => this.onKeyUp(eButtonType.EBT_PLUS));
    this.buttonPlus.addEventListener('mouseout', () => this.onKeyUp(eButtonType.EBT_PLUS));

    if (this._disableCondition()) {
      this.amountInput.readOnly = true;
      this.buttonMinus.disabled = true;
      this.buttonPlus.disabled = true;
    }

    window.OPWrapperService.localizations.addLocalizationChangedCallback(this.onLocalizationChanged, this);
    this.onLocalizationChanged();
  }

  onInputBlur(event) {

  }

  onCheckboxChanged(event) {
    SoundManager.play(eSoundsType.EST_CLICK, 1);
  }

  onPlusClicked() {
    SoundManager.play(eSoundsType.EST_CLICK, 1);
  }

  onMinusClicked() {
    SoundManager.play(eSoundsType.EST_CLICK, 1);
  }

  onButtonPressed(type) {

  }

  onLocalizationChanged() {
    const element = this.element.getElementsByClassName('rates-setting__switch-title')[0];
    element.innerText = window.OPWrapperService.localizations.getLocalizedText('auto');
  }

  onKeyDown(type) {
    this[`timeout_${type}`] = setTimeout(() => {
      this[`interval_${type}`] = setInterval(() => {
        this.onButtonPressed(type);
        SoundManager.play(eSoundsType.EST_CLICK, 1);
      }, 100)
    }, 400);
  }

  onKeyUp(type) {
    clearTimeout(this[`timeout_${type}`]);
    clearInterval(this[`interval_${type}`])
  }

  onModelChanged() {
    this.amountInput.readOnly = this._disableCondition();
    this.buttonMinus.disabled = this._disableCondition();
    this.buttonPlus.disabled = this._disableCondition();
  }

  _disableCondition() {
    return this.model.betSubmitted;
  }

  destroy() {
    this.model.onChange.remove(this.onModelChanged.bind(this));
  }
}
