import GlobalDispatcher from './events/GlobalDispatcher';
import eWSEventId from './api/eWSEventId';
import { AsyncWithCancel, sleep } from './utils/promise';
import StatModel from './models/StatModel';
import GameModel from './models/GameModel';
import eGameState from './models/eGameState';

export default new class ManagerResult {
  constructor() {
    this.controllerBG = null;
    this.conotrollerScore = null;
    this.controllerWaitingBets = null;
    this.needUpdate = false;

    this.createFinishAnimation = function* (data) {
      const delay = data.params.reason === 'jackpot' ? 6000 : 2000;
      yield sleep(delay);
      StatModel.loadBetsFromCache();
      if (data.params.awards.length > 0) {
        yield this.conotrollerScore.show(data);
      }
      yield this.controllerBG.animateBlocker(1);
      yield this.controllerBG.resetPositions();
      this.controllerWaitingBets.show();
      yield this.controllerBG.animateBlocker(0);
      this.controllerWaitingBets.animateProgress();
    };
  }

  init() {
    document.addEventListener('visibilitychange', this._onVisibilityChange.bind(this));
    GlobalDispatcher.add(eWSEventId.EWEI_START, this._onStart, this);
    GlobalDispatcher.add(eWSEventId.EWEI_PROGRESS, this._onProgress, this);
    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this._onFinish, this);
    GlobalDispatcher.add(eWSEventId.EWEI_AUTH, () => setTimeout(this.updateGame.bind(this))); // hack for go out from GlobalDispatcher because updateGame use GlobalDispatcher too
  }

  updateGame() {
    this.needUpdate = true;
    if (GameModel.state === eGameState.EGS_RESULTS) {
      if (this.animation) this.animation.cancel();
        this.controllerBG.resetPositions();
      this.controllerWaitingBets.show();
      this.controllerWaitingBets.animateProgress();
    }
    this.conotrollerScore.hide();
  }

  _onVisibilityChange() {
    console.warn('visibilitychange');
    if (document.hidden) {
      // window.miniPreloader.show();
    } else {
      this.updateGame();
    }
  }

  _onStart(data) {
    if (this.animation)
      this.animation.cancel();

    if (this.needUpdate)
      window.miniPreloader.hide();

    if (this.needUpdate && GameModel.state === eGameState.EGS_PROGRESS) {
      this.controllerBG.setX(data.params.x);
      this.needUpdate = false
    }
  }

  _onProgress(data) {
    if(this.conotrollerScore)
      this.conotrollerScore.hide();


    if (this.needUpdate)
      window.miniPreloader.hide();

    if (this.needUpdate && GameModel.state === eGameState.EGS_PROGRESS) {
      this.controllerBG.setX(data.params.x);
      this.needUpdate = false
    }
  }

  _onFinish(data) {
    if (!this.controllerBG || !this.conotrollerScore || !this.controllerWaitingBets || document.hidden) return;
    this.animation = new AsyncWithCancel(this.createFinishAnimation(data));
    this.animation.call();
  }
}
