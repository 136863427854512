import StatModel from '../models/StatModel';
import SoundManager from '../soundManager/SoundManager';
import eSoundsType from '../preloaders/sounds/eSoundsType';
import { formatCurrency } from '../utils/currency';

export const eTabType = {
  ETT_BETS: 'bets',
  ETT_MY_BETS: 'myBets',
};

export default class ControllerTable {
  constructor() {
    this._PIDsWithoutCurrency = ['937'];
    const launchedForPid = window.OPUtility.urlParser.getParam('launchedForPid') || window.OPUtility.urlParser.getParam('pid')
    this._withoutCurrency = this._PIDsWithoutCurrency.includes(launchedForPid);
    this.currentTab = eTabType.ETT_BETS;
    this.tabsContainer = document.getElementsByClassName('tabs__head')[0];

    this.tabsButtons = this.tabsContainer.getElementsByTagName('li');
    for (const item of this.tabsButtons) {
      item.addEventListener('click', this._onTabClicked.bind(this));
    }

    this.element = document.getElementById('table');

    this.body = document.getElementById('tbody');
    this.thead = this.element.getElementsByTagName('thead')[0];

    this._redrawRows();

    StatModel.onBetsInfoChanged.add(this._onBetsInfoChanged.bind(this));
    window.OPWrapperService.localizations.addLocalizationChangedCallback(this._updateLocalization, this);
    this._updateLocalization();
  }

  _onBetsInfoChanged() {
    this._redrawRows();
  }

  _onTabClicked(event) {
    event.preventDefault();
    SoundManager.play(eSoundsType.EST_CLICK, 1);
    for (const item of this.tabsButtons) {
      item.classList.remove('active');
    }
    event.target.parentNode.classList.add('active');
    this.currentTab = event.target.getAttribute('data-type');
    this._redrawRows();
  }

  _redrawRows() {
    while (this.body.firstChild) {
      this.body.removeChild(this.body.firstChild);
    }

    this._renderBets();
  }

  _renderBets() {
    this._updateLocalization();
    const isAllBets = this.currentTab === eTabType.ETT_BETS;
    for (const item of StatModel[isAllBets ? 'betsInfo' : 'myBetsInfo']) {
      const row = this.body.insertRow();
      if (item.isCurrentPlayer && isAllBets) row.style.setProperty('color', '#DDC542');
      if (item.coef && item.coef >= 1) row.classList.add('collected');
      const currency = this._withoutCurrency && !item.isCurrentPlayer ? '' : item.currency;
      this._createCell(row, isAllBets ? item.clientId : item.time);
      this._createCell(row, `${formatCurrency(item.amount, item.decimals, false)} ${currency}`);
      this._createCell(row, item.coef || '---');
      this._createCell(row, item.winAmount && item.winAmount > 0 ? `${formatCurrency(item.winAmount, item.decimals, false)} ${currency}`: '---');
    }
  }

  _createCell(row, text) {
    const cell = row.insertCell();
    cell.innerHTML = text;
    return cell;
  }

  _getLocalization(key, params) {
    return window.OPWrapperService.localizations.getLocalizedText(key, params);
  }

  _updateLocalization() {
    const header = this.thead.rows[0];
    const isAllBets = this.currentTab === eTabType.ETT_BETS;
    header.cells[0].innerText = this._getLocalization(isAllBets ? 'username' : 'time');
    header.cells[1].innerText = this._getLocalization('bet_column');
    header.cells[2].innerText = this._getLocalization('take_coef');
    header.cells[3].innerText = this._getLocalization('win_amount');

    for (const button of this.tabsButtons) {
      const a = button.getElementsByTagName('a')[0];
      const type = a.getAttribute('data-type');
      a.innerText = this._getLocalization(type === eTabType.ETT_BETS ? 'players' : 'my_bets')
    }
  }
}
