import {gsap, Linear} from "gsap";
import ImagePreloader from '../../preloaders/images/ImagePreloader';

export default class ControllerMovingBGBase {
  constructor(container) {
    this.step = 12;
    this.duartion = 0.1;
    this.stepDevider = 1;
    this.predictionSteps = 10;
    this.container = new PIXI.Container();
    container.addChild(this.container);
  }

  setX(value) {
    this.reset();
    gsap.killTweensOf(this.container);
    this.container.x = -value / this.stepDevider;
    this.onUpdate();
  }

  syncAnimation(data) {
    gsap.killTweensOf(this.container);
    const duration = this.duartion + this.duartion * this.predictionSteps;
    const x = (-data.params.x - this.step * this.predictionSteps) / this.stepDevider;
    gsap.to(this.container, {
      duration: duration,
      x,
      ease: Linear.easeNone,
      onUpdate: () => {
        this.onUpdate();
      }
    });
  }

  onUpdate() {

  }

  stopAnimation() {
    gsap.killTweensOf(this.container);
  }

  reset() {
    this.container.x = 0;
  }

  _createBg(name, x, y) {
    const result = ImagePreloader.createImage(name);
    result.x = x;
    result.y = y;
    return result;
  }
}
