import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import eEventType from '../events/eEventType';
import GameModel from '../models/GameModel';
import eGameState from '../models/eGameState';

export default class ControllerCounter {
  constructor() {
    this.label = document.getElementById('counter');

    if (GameModel.state === eGameState.EGS_RESULTS) {
      this.reset();
    }

    GlobalDispatcher.add(eWSEventId.EWEI_PROGRESS, this.onProgress, this);
    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this.onFinish, this);
    GlobalDispatcher.add(eEventType.EET_RESET, this.reset, this);
    GlobalDispatcher.add(eWSEventId.EWEI_START, () => this.setText(1));
  }

  onFinish(data) {
    if (data.params.reason === 'jackpot' || data.params.awards.length > 0) {
      this.label.innerText = '';
    } else {
      this.setText(data.params.coef);
    }
  }

  onProgress(data) {
    this.setText(data.params.coef)
  }

  setText(value) {
    this.label.innerText = '×' + this._round(value);
    const fontSize = 4 + Math.min(3, 0.2 * value);
    this.label.style.setProperty('font-size', `${fontSize}rem`);
  }

  reset() {
    this.label.innerText = window.OPWrapperService.localizations.getLocalizedText('make_your_bets');
  }

  _round(value) {
    let result = Math.round(value * 100) / 100;
    if (!/\.\d{2}$/.test(result)) {
      const decimals = result.toString().match(/\.\d$/);
      result += decimals ? '0' : '.00';
    }
    return result;
  }
}
