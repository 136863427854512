import eImageType from '../../preloaders/images/eImageType';

export const eClusterType = {
  ECT_DAY: 'day',
  ECT_NIGHT: 'night',
  ECT_GREENERY: 'greenery',
  ECT_MOUNTS: 'mounts'
};

export const clustersInfo = {
  [eClusterType.ECT_DAY]: {
    [eClusterType.ECT_GREENERY]: [
      {
        obj: [
          {
            image: eImageType.EIT_TREE,
            x: 80,
            y: 20,
          },
          {
            image: eImageType.EIT_TREE,
            x: 0,
            y: 80,
            scale: 0.7
          },
          {
            image: eImageType.EIT_BUSH,
            x: 120,
            y: 120,
          }
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_TREE,
            x: 0,
            y: 20,
          },
          {
            image: eImageType.EIT_TREE,
            x: 60,
            y: 80,
            scale: 0.7
          }
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_TREE,
            x: 0,
            y: 20,
          }
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_TREE,
            x: 100,
            y: 20,
          },
          {
            image: eImageType.EIT_BUSH,
            x: 0,
            y: 120,
          }
        ]
      },
    ],
    [eClusterType.ECT_MOUNTS]: [
      {
        obj: [
          {
            image: eImageType.EIT_MOUNT0,
            x: 10,
            y: 10,
          },
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_MOUNT0,
            x: 10,
            y: 10,
          },
          {
            image: eImageType.EIT_MOUNT1,
            x: 80,
            y: 80,
          },
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_MOUNT0,
            x: 30,
            y: 10,
          },
          {
            image: eImageType.EIT_MOUNT0,
            x: 0,
            y: 80,
            scale: 0.6,
          },
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_MOUNT1,
            x: 0,
            y: 120,
          },
        ]
      }
    ],
  },
  [eClusterType.ECT_NIGHT]: {
    [eClusterType.ECT_GREENERY]: [
      {
        obj: [
          {
            image: eImageType.EIT_TREE_NIGHT1,
            x: 120,
            y: -150,
          },
          {
            image: eImageType.EIT_TREE_NIGHT0,
            x: 80,
            y: 20,
          },
          {
            image: eImageType.EIT_TREE_NIGHT0,
            x: 0,
            y: 80,
            scale: 0.7
          },
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_TREE_NIGHT1,
            x: 60,
            y: -60,
            scale: 0.7
          },
          {
            image: eImageType.EIT_TREE_NIGHT0,
            x: 0,
            y: 20,
          },
          {
            image: eImageType.EIT_TREE_NIGHT0,
            x: 50,
            y: 80,
          },
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_TREE_NIGHT0,
            x: 0,
            y: 20,
          }
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_TREE_NIGHT1,
            x: 0,
            y: -150,
          },
          {
            image: eImageType.EIT_TREE_NIGHT0,
            x: 340,
            y: 20,
          },
        ]
      },
    ],
    [eClusterType.ECT_MOUNTS]: [
      {
        obj: [
          {
            image: eImageType.EIT_MOUNT_NIGHT0,
            x: 10,
            y: 10,
          },
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_MOUNT_NIGHT0,
            x: 10,
            y: 40,
          },
          {
            image: eImageType.EIT_MOUNT_NIGHT0,
            x: 180,
            y: 0,
          },
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_MOUNT_NIGHT0,
            x: 10,
            y: 0,
          },
          {
            image: eImageType.EIT_MOUNT_NIGHT1,
            x: 80,
            y: 120,
          },
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_MOUNT_NIGHT0,
            x: 30,
            y: 10,
          },
          {
            image: eImageType.EIT_MOUNT_NIGHT0,
            x: 0,
            y: 80,
            scale: 0.6,
          },
        ]
      },
      {
        obj: [
          {
            image: eImageType.EIT_MOUNT_NIGHT1,
            x: 0,
            y: 50,
          },
        ]
      }
    ],
  }
};
