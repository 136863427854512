import eSmileType from '../preloaders/images/eSmileType';
import ImagePreloader from '../preloaders/images/ImagePreloader';
import { sendEmoji } from '../api/wsRequests';
import eImageType from '../preloaders/images/eImageType';

export default class ControllerSmilesPanel {
  constructor() {
    this.buttonToggle = document.getElementById('smilesPanel').getElementsByClassName('buttonToggle')[0];
    this.slider = document.getElementById('smilesSlider');

    this.buttonToggle.addEventListener('click', this.onSliderToggle.bind(this));
    this.opened = false;

    for (const key in eSmileType) {
      const type = eSmileType[key];
      const element = document.createElement('img');
      element.setAttribute('src', ImagePreloader.getSrc(type));
      element.addEventListener('click', () => this.onSmileClicked(type));
      this.slider.prepend(element);
    }
  }

  onSmileClicked(type) {
    sendEmoji(type);
  }

  onSliderToggle() {
    this.opened = !this.opened;

    this.slider.classList[this.opened ? 'add' : 'remove']('show');
    const buttonType = this.opened ? eImageType.EIT_CLOSE_SMILES : eImageType.EIT_OPEN_SMILES;
    this.buttonToggle.setAttribute('src', ImagePreloader.getSrc(buttonType));
  }
}
